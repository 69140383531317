<template>
  <div class="group-list-container">
    <group-cards :groups='groups'></group-cards>
  </div>
</template>

<script>
import GroupCards from '../../components/GroupCards.vue'
export default {
  computed: {
    groups () {
      return this.$store.state.serviceGroups
    }
  },
  components: {
    GroupCards
  },
  methods: {
    routeToHomepage () {
      this.$router.push('/')
    }
  }
}
</script>
