export function isElementLoaded (type, attr, value) {
  return !!document.querySelector(`${type}[${attr}="${value}"]`)
}

export const appendScript = (url) => {
  const script = document.createElement('script')
  script.setAttribute('defer', true)
  script.setAttribute('src', url)
  document.head.appendChild(script)
}
