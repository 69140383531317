import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// add defaults here

// instance.defaults.headers.common["SOMETHING"] = "something";

instance.interceptors.request.use(config => {
  // console.log('request interceptor ', config)
  return config
})

instance.interceptors.response.use(res => {
  // console.log('response interceptor', res)
  return res
})

export default instance
