<template>
  <div id="app">
    <app-layout>
      <router-view v-if="created"></router-view>
    </app-layout>
    <notifications group="admin" position="bottom center" />
  </div>
</template>

<script>
import Vue from 'vue'
import Layout from '@/router/layouts/AppLayout.vue'
import DynamicService from '@/router/views/DynamicService.vue'

export default {
  components: {
    appLayout: Layout
  },
  methods: {
    dispatchServices () {
      this.$store
        .dispatch('fetchServices')
        .then((services = []) => {
          Vue.config.ignoredElements =
            services.map((service) => service.tagname) || []
          // add routes dynamically for each service
          services.map((service) => {
            this.$router.addRoute(
              {
                name: service.tagname,
                path: `/service/${service.tagname}`,
                component: DynamicService,
                props: { service },
                meta: {
                  title: service.title
                }
              }
            )
            return service
          })
        })
        .catch(() => {})
        .finally(() => {
          this.created = true
        })

      this.$store.dispatch('fetchServiceGroups')
    }
  },
  created () {
    this.dispatchServices() // initial load
    setInterval(() => {
      this.dispatchServices()
    }, 300 * 1000) // refresh every 300 seconds (the token reloads every 5 minutes too)
  },
  data () {
    return {
      created: false
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  height: 100vh;
  width: 100%;
  color: @GreyShade;
}
body{
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
a {
  text-decoration: none;
}
</style>
