<template>
  <div class="not-found-container">
     <div class="message-wrapper">
        <h3>Oops!</h3>
        <div>
          <h5>We can't seem to find the page you're looking for</h5>
        </div>
       <span>Error code: 404</span>
       <p>Here are some useful links instead:</p>
     </div>
     <div class="image-wrapper">
       <img src="@/assets/not-found.jpg" alt="not-found">
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .message-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .image-wrapper {
      width: 100%;
      max-width: 600px;
      padding-right: 1rem;
      padding-left: 1rem;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
</style>
