import Home from './views/AppHome.vue'
import NotFound from './views/NotFound.vue'
import GroupList from './views/GroupList.vue'
import GroupDetails from './views/GroupDetails.vue'

export default [
  {
    path: '/',
    name: 'AppHome',
    component: Home
  },
  {
    path: '/groups',
    name: 'GroupList',
    component: GroupList
  },
  {
    path: '/groups/:slug',
    name: 'GroupDetails',
    component: GroupDetails
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Not Found'
    }
  }
]
