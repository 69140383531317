import Keycloak from 'keycloak-js'

const keyCloakClientID = process.env.NODE_ENV === 'production' ? 'admin_portal' : 'admin_portal_staging'

const initOptions = {
  realm: 'Telecom',
  url: 'https://auth.ringsq.io/auth/',
  clientId: keyCloakClientID
}

export default {
  install (Vue, opts) {
    const options = { ...initOptions, ...opts }
    const keycloak = Keycloak(initOptions)

    Vue.prototype.$keycloak = keycloak

    keycloak.onAuthRefreshSuccess = function () {
      options.onTokenUpdated(keycloak)
    }

    keycloak.onAuthRefreshError = function () {
      keycloak.clearToken()
      keycloak.login()
    }

    keycloak.onTokenExpired = function () {
      keycloak.updateToken(60).then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed ' + refreshed)
        } else {
          console.log('Token not refreshed, valid for ' +
          Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).catch(() => {
        console.log('Failed to refresh token')
      })
    }

    keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
        checkLoginIframe: false,
        pkceMethod: 'S256'
      })
      .then((authenticated) => {
        if (authenticated) {
          options.onTokenUpdated(keycloak)
          options.onReady()
        } else {
          keycloak.login()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
