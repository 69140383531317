import axios from '../../server'

const state = {
  user: null
}

const getters = {
  isLogged (state) {
    return state.user ? state.user.authenticated : false
  }
}

const actions = {
  login ({ commit }, user) {
    commit('setUser', user)
    axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
  }
}

const mutations = {
  setUser (state, userData) {
    state.user = userData

    const username = userData.tokenParsed.preferred_username // eugeny.ginzburg
    const roles = JSON.stringify(userData.tokenParsed.realm_access.roles) // ['offline_access', 'uma_authorization', 'user']
    document.cookie = `username=${username}`
    document.cookie = `roles=${roles}` // pass to actions.js for Cerbos req body
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
