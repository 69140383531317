import server from '../server'
import SwaggerClient from 'swagger-client'
import Vue from 'vue'

const APIservices = `${process.env.VUE_APP_SERVICES_API}/services`
const APIgroups = `${process.env.VUE_APP_SERVICES_API}/groups`

// Prepare Swagger Client
const httpsInterceptor = (req) => {
  console.log('executing top level request interceptor')
  req.url = req.url.replace('http:', 'https:')
  req.mode = 'cors'
  return req
}
const swaggerClient = new SwaggerClient({ url: process.env.VUE_APP_SWAGGER_API, requestInterceptor: httpsInterceptor })

// 1. SERVICES
export const fetchServices = ({ commit }) => {
  return new Promise((resolve, reject) => {
    server.get(APIservices).then((res) => {
      const resArr = res.data
      const req = makeReqBody(resArr, 'service') // make request body

      swaggerClient
        .then(({ apis }) => apis.CerbosService.CerbosService_CheckResourceBatch({ body: req }))
        .then(
          ({ body }) => {
            const permittedServices = getPermittedEntities(resArr, body)
            commit('storeServices', permittedServices) // <<<---- commit with the array of permitted services
            resolve(permittedServices)
          }
        )
    }).catch(err => {
      Vue.notify({
        group: 'admin',
        title: 'Failed to fetch Services',
        text: `${err.response ? err.response.data.msg : ''}`,
        type: 'error'
      })
    })
  })
}

// 2. GROUPS
export const fetchServiceGroups = ({ commit }) => {
  server.get(APIgroups).then((res) => {
    const resArr = res.data
    const req = makeReqBody(resArr, 'group') // make request body

    swaggerClient
      .then(({ apis }) => apis.CerbosService.CerbosService_CheckResourceBatch({ body: req }))
      .then(
        ({ body }) => {
          const permittedGroups = getPermittedEntities(resArr, body)
          commit('storeGroups', permittedGroups) // <<<---- commit with the array of permitted groups
        }
      )
  }).catch(err => {
    Vue.notify({
      group: 'admin',
      title: 'Failed to fetch service Groups',
      text: `${err.response ? err.response.data.msg : ''}`,
      type: 'error'
    })
  })
}

// compare all groups/services (resArr from registry.ringsq.io/groups or /services) against the permitted groups (swaggerClient-returned resourceIds having EFFECT_ALLOW)
function getPermittedEntities (resArr, body) {
  const filteredArr = []
  for (const objSwagger of body.results) { // results:[{resourceId:"1", actions:{view:"EFFECT_DENY"}}, {resourceId:"2", actions:{view:"EFFECT_ALLOW"}}]
    const bResourceId = objSwagger.resourceId
    const bActions = objSwagger.actions.view

    if (bActions === 'EFFECT_ALLOW') {
      for (const objM5 of resArr) {
        if (objM5.id.toString() === bResourceId.toString()) {
          filteredArr.push(objM5)
        }
      }
    }
  }
  return filteredArr
}

// make request body
function makeReqBody (resArr, kind) {
  // (1) prepare requestId
  const REQID = getRandomId()

  // (2) prepare username and roles
  const [USERNAME, ROLES] = getCookies()

  // (3) prepare array of resources
  const RESOURCES = []
  for (const objAttr of resArr) { // [{id:1, name:Administration, slug:admin, weight:99, icon:..}, {id:2, name:Development, slug:development, weight:90, icon:..}]
    const thisResource = {
      actions: ['view'],
      resource: {
        kind: `portal:${kind}`, // portal:group / portal:service
        policyVersion: 'default',
        id: objAttr.id.toString(), // "1" (must be a string)
        attr: objAttr // {id: 1, name: "Administration", slug: "admin", ..}
      }
    }
    RESOURCES.push(thisResource)
  }

  // make the body using (1), (2), (3)
  const req = {
    requestId: REQID,
    principal: {
      id: USERNAME,
      policyVersion: 'default',
      roles: ROLES
    },
    resources: RESOURCES
  }
  // console.log(JSON.stringify(req))

  return req
}

// get username/roles in cookies from JWT (see modules/auth.js)
function getCookies () {
  const [usernameKV, rolesKV] = document.cookie.split('; ') // username=xx; roles=[x1, x2]
  const [, username] = usernameKV.split('=')
  let [, roles] = rolesKV.split('=')
  roles = JSON.parse(roles)
  return [username, roles]
}

// get random requestId as a string of 5 hex numbers (c2db17b8-4f9f-4fb1-acfd-9162a02be42b)
function getRandomId () {
  let id = ''
  const arrPowers = [10, 5, 5, 5, 15]
  for (const power of arrPowers) {
    id += `${Math.floor(Math.random() * (10 ** power)).toString(16)}-`
  }
  id = id.slice(0, -1)
  return id
}
