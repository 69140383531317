<template>
  <div class="layout-container">
    <app-header @toggle-drawer="toggleDrawer"></app-header>
    <main-content :drawer="drawer">
      <slot></slot>
    </main-content>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from '@/components/AppHeader.vue'
import Footer from '@/components/AppFooter.vue'
import MainContent from '@/components/MainContent.vue'
export default {
  data () {
    return {
      drawer: false
    }
  },
  components: {
    appHeader: Header,
    appFooter: Footer,
    MainContent
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}
</style>
