<template>
  <div ref="serviceContainer" class="service-container">
    <div class="service-top-bar">
      <div class="service-title">{{ service.title }}</div>
    </div>
    <app-spinner v-if="loader"></app-spinner>
    <div v-if="showError" class="error-container">
      <h5>Something went wrong.</h5>
      <b>
        Service taking too long to load. Possible reasons can be,
      </b>
      <p>1. Your network might be slow</p>
      <p>2. Failed to load service from the server</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Spinner from '@/components/shared/LoaderSpinner.vue'
import { isElementLoaded, appendScript } from '../../utils/helper'

export default {
  props: ['service'],
  data () {
    return {
      instance: null,
      loader: true,
      interval: null,
      showError: false
    }
  },
  components: {
    appSpinner: Spinner
  },
  destroyed () {
    clearInterval(this.interval)
    this.loader = false
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      // access to component instance via `vm`
      vm.checkServiceLoad()
      vm.renderService(vm.instance)
      // add web-component JS to dom
      if (!isElementLoaded('script', 'src', vm.service.js_url)) {
        appendScript(vm.service.js_url)
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.interval)
    this.loader = false
    next()
  },
  methods: {
    renderService (node) {
      const query = Object.keys(this.$route.query).length
        ? this.$route.query
        : ''
      const attrsString = `:token="accessToken" ${
        query ? ':search-params="params"' : ''
      }`
      const ServiceClass = Vue.extend({
        template: `<${this.service.tagname} ${attrsString}></${this.service.tagname}>`,
        props: ['service', 'params']
      })
      const IframeClass = Vue.extend({
        template: `<iframe src="${this.service.js_url}" frameborder="0" scrolling="yes" seamless="seamless" style="display:block; width:100%; height:100vh;"></iframe>`
      })
      const instance = new ServiceClass({
        propsData: { service: this.service, params: JSON.stringify(query) },
        name: this.service.tagname,
        store: this.$store,
        computed: {
          accessToken () {
            return this.$store.state.auth.user.token
          }
        }
      })
      const iframeInstance = new IframeClass({
        propsData: { service: this.service, params: JSON.stringify(query) },
        name: this.service.tagname,
        store: this.$store,
        computed: {
          accessToken () {
            return this.$store.state.auth.user.token
          }
        }
      })
      if (this.service.js_url.includes('appsmith')) {
        iframeInstance.$mount()
      } else {
        instance.$mount()
      }
      if (node) {
        if (this.service.js_url.includes('appsmith')) {
          this.$refs.serviceContainer.replaceChild(iframeInstance.$el, node)
        } else {
          this.$refs.serviceContainer.replaceChild(instance.$el, node)
        }
      } else {
        if (this.service.js_url.includes('appsmith')) {
          this.$refs.serviceContainer.appendChild(iframeInstance.$el)
        } else {
          this.$refs.serviceContainer.appendChild(instance.$el)
        }
      }
      if (this.service.js_url.includes('appsmith')) {
        this.instance = iframeInstance.$el
      } else {
        this.instance = instance.$el
      }
    },
    checkServiceLoad () {
      this.loader = true
      this.$nextTick(() => {
        if (this.service.js_url.includes('appsmith')) {
          this.interval = setInterval(() => {
            if (isElementLoaded('iframe', 'src', this.service.js_url)) {
              clearInterval(this.interval)
              this.loader = false
            }
          }, 100)
        } else {
          if (document.querySelector(`${this.$route.name}`)) {
            let totalWaitTime = 0
            this.interval = setInterval(() => {
              totalWaitTime += 500
              if (document.querySelector(`${this.$route.name}`).shadowRoot || document.querySelector(`${this.$route.name}`).isConnected) {
                this.loader = false
                this.showError = false
                clearInterval(this.interval)
              } else if (totalWaitTime >= 20000) {
                this.loader = false
                this.showError = true
              }
            }, 500)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.service-container {
  padding: 20px;
  overflow: auto;
  .service-top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
    .service-title {
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: bold;
    }
  }
  .error-container{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
</style>
