import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Keycloak from './keycloak/keycloak'
import * as Sentry from '@sentry/vue'
import Notifications from 'vue-notification'
import './less/main.less'

Vue.config.productionTip = false

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  attachStacktrace: true,
  environment: process.env.NODE_ENV,
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /https?:\/\/((cdn|www)\.)?m5webassets\.blob\.core\.windows\.net\/scripts\/([\S]*js$)/
  ]
})

Vue.use(Keycloak, {
  onReady: () => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  },
  onTokenUpdated: keycloak => {
    // store auth/user data to vuex store
    store.dispatch('auth/login', {
      authenticated: keycloak.authenticated,
      name: keycloak.tokenParsed.name,
      tokenParsed: keycloak.tokenParsed,
      refreshTokenParsed: keycloak.refreshTokenParsed,
      token: keycloak.token,
      refreshToken: keycloak.refreshToken
    })
  }
})
Vue.use(Notifications)
