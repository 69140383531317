<template>
  <div class="main-content-container">
    <side-drawer :drawer="drawer"></side-drawer>
    <slot></slot>
  </div>
</template>

<script>
import SideDrawer from '@/components/SideDrawer.vue'
export default {
  props: {
    drawer: Boolean
  },
  components: {
    SideDrawer
  }
}
</script>

<style lang="less" scoped>
.main-content-container {
  display: grid;
  grid-template-columns: auto 1fr;
  overflow: auto;
}
</style>
