<template>
  <div class="footer-container">
      Copyright &copy; {{ getYear }} RingSquared. All rights reserved.
  </div>
</template>

<script>
export default {
  computed: {
    getYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="less" scoped>
.footer-container {
  background: @White;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-shadow: 0 1px 10px 0 @BoxShadow;
  font-size: 12px;
  z-index: 1;
  color: @PrimaryColor;
}

</style>
