<template>
  <div class="groups-container">
    <router-link
      :key="group.slug"
      v-for="group in groups"
      class="service-item"
      :to="`groups/${group.slug}`"
    >
      <div class="card-layout-cont">
        <img class="card-image" :src="group.icon" />
        <span class="card-title">{{ group.name }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array
    }
  }
}
</script>

<style lang="less" scoped>
.groups-container {
  overflow: auto;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 60px;
  height: auto;
  align-items: flex-start;
  padding: 1rem;
  flex-wrap: wrap;
  .service-item {
    .display-card();
  }
}
</style>
