<template>
  <div class="home-container">
    <div v-if="groups.length">
      <div class="group-title">Groups</div>
      <group-cards :groups="groups"></group-cards>
    </div>
    <div v-if="services.length">
      <div class="group-title">Services</div>
      <div class="services-container">
        <router-link
          :key="service.tagname"
          v-for="service in services"
          class="text-center service-item"
          :to="`service/${service.tagname}`"
          data-testid="service-link"
        >
          <div class="card-layout-cont">
            <img class="card-image" :src="service.service_icon" />
            <span class="card-title">{{ service.title }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import GroupCards from '../../components/GroupCards.vue'
export default {
  computed: {
    services () {
      return this.$store.state.appServices.filter(
        (service) => !service.group_id
      )
    },
    groups () {
      return this.$store.state.serviceGroups
    }
  },
  components: {
    GroupCards
  }
}
</script>

<style lang="less" scoped>
.home-container {
  overflow: auto;
  padding: 20px;
  .group-title {
    padding: 8px 16px;
    color: @PrimaryColor;
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 2rem;
    font-weight: bold;
  }
  .services-container {
    overflow: auto;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 60px;
    height: auto;
    align-items: flex-start;
    padding: 1rem;
    flex-wrap: wrap;
    .service-item {
      .display-card();
    }
  }
}
</style>
