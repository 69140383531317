<template>
  <header class="header">
    <div @click="toggleDrawer" class="toggle-icon" name="menu">
      <img src="../assets/hamburger.svg" class="menu-icon" alt="" />
    </div>
    <router-link :to="'/'" class="logo">
      <img src="../assets/logo.png" alt="logo" />
    </router-link>
    <div class="profile-icon" data-profile-icon>
      <span class="name" v-if="$keycloak && $keycloak.idTokenParsed && $keycloak.idTokenParsed.name">{{ $keycloak.idTokenParsed.name }}</span>
      <img src="../assets/account.svg" alt="profile" />
      <img src="../assets/down.svg" alt="down">
      <div class="menu-option">
        <div class="menu-option-item" @click="signOut">
          <img src="../assets/logout.svg" alt="logout">
          <span class="'signout'">Sign out</span>
         </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'App-Header',
  methods: {
    signOut () {
      this.$keycloak.logout()
    },
    toggleDrawer () {
      this.$emit('toggle-drawer')
    }
  }
}
</script>

<style lang="less" scoped>
 .header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: @White;
    box-shadow: 0 1px 10px 0 @BoxShadow;
    padding: 0 10px;
    height: 70px;
    z-index: 5;
    .toggle-icon {
      margin-top: 10px;
      width: 50px;
      display: flex;
      justify-content: center;
       .menu-icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
    .logo {
      width: 200px;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .profile-icon {
      margin-right: 20px;
      display: flex;
      flex: 1;
      justify-content: flex-end;
      position: relative;
      align-items: center;
      cursor: pointer;
      height: 100%;
      @media @mobile {
        margin-right: 0;
      }
      &:hover {
        .menu-option {
          opacity: 1;
        }
      }
      .name {
        font-size: 14px;
        font-weight: bold;
        color: @PrimaryColor;
        margin-right: 10px;
        @media @mobile {
          display: none;
        }
      }
      img {
        width: 30px;
        height: 30px;
      }
      .menu-option {
        position: absolute;
        opacity: 0;
        box-shadow: 0 1px 10px 0 @BoxShadow;
        background: @White;
        width: 150px;
        top: 70px;
        border-radius: 4px;
        transition: opacity 200ms ease-in-out;
        .menu-option-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px;
          &:hover{
            background: @LightGrey;
            border-radius: 4px;
          }
          span {
            font-size: 14px;
            font-weight: 500;
            color: @PrimaryColor;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
</style>
