<template>
   <aside class="sidebar" data-sidebar :class="{ show: drawer }">
      <div class="middle-sidebar">
        <ul class="sidebar-list">
          <li class="sidebar-list-item" v-for="group in groups" :key="group.slug"  :class="{
            active: isGroupActive('GroupDetails', group.slug)
          }">
            <router-link :to="`/groups/${group.slug}`" class="sidebar-link" data-testid="group-link">
              <img
              :src="group.icon"
              class="sidebar-icon"
            />
              <div class="hidden-sidebar">{{ group.name }}</div>
            </router-link>
          </li>
          <li class="sidebar-list-item" v-for="service in services" :key="service.slug" :class="{
            active: isActive(service.tagname)}">
            <router-link :to="`/service/${service.tagname}`" class="sidebar-link">
              <img
              :src="service.service_icon"
              :alt="service.title"
              class="sidebar-icon"
            />
              <div class="hidden-sidebar">{{  service.title }}</div>
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
</template>

<script>
export default {
  props: {
    drawer: Boolean
  },
  methods: {
    isActive (menuItem) {
      return this.routeName === menuItem
    },
    isGroupActive (item, slug) {
      return this.routeName === item && this.$route.params.slug === slug
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    },
    services () {
      return this.$store.state.appServices.filter(
        (service) => !service.group_id
      )
    },
    groups () {
      return this.$store.state.serviceGroups
    }
  }
}
</script>

<style lang="less" scoped>
.sidebar {
  flex-shrink: 0;
  overflow: hidden;
  width: 75px;
  box-shadow: 0 1px 10px 0 @BoxShadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  transition: width 200ms ease-in-out;
  position: sticky;
  left: 0;
  z-index: 3;
  background: @PrimaryColor;
  border-top-right-radius: 8px;
  &.show {
    width: 200px;
    .hidden-sidebar {
      width: 100%;
      height: auto;
      opacity: 1;
    }
    .sidebar-link {
      justify-content: flex-start;
    }
  }
  .hidden-sidebar {
    opacity: 0;
    width: 0;
    transition: opacity 200ms;
    margin-left: 10px;
  }
  .middle-sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    width: 100%;
    .sidebar-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      .sidebar-list-item {
        position: relative;
        height: 70px;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        &.active {
          background-color: @LightGrey;
          .sidebar-link {
            color: @PrimaryColor;
            .sidebar-icon {
              filter: none;
            }
          }
          &::before{
            content: "";
            background-color: @Red;
            height: 100%;
            left: 0;
            width: 3px;
            position: absolute;
          }
        }
        .sidebar-link {
          display: flex;
          width: 100%;
          padding: .5rem 0;
          color: @White;
          text-decoration: none;
          align-items: center;
          padding-left: 25px;
          height: 100%;
          .sidebar-icon {
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}
</style>
