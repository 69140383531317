<template>
  <div class="group-container">
    <div class="group-actions" v-if="isGroupSlugValid">
      <div @click="$router.back()" class="icon-container">
        <img src="../../assets/arrow-back.svg" alt="Back" />
      </div>
    </div>
    <div class="services-container" v-if="services.length">
      <router-link
        :key="service.tagname"
        v-for="service in services"
        class="text-center service-item"
        :to="`/service/${service.tagname}`"
      >
        <div class="card-layout-cont">
          <img class="card-image" :src="service.service_icon" />
          <span class="card-title">{{ service.title }}</span>
        </div>
      </router-link>
    </div>
    <div class="no-services-found" v-if="!services.length && isGroupSlugValid">
      <h3>Oops!</h3>
      <h6>No services found in this group</h6>
    </div>
    <div class="group-not-found">
      <not-found v-if="!isGroupSlugValid"></not-found>
    </div>
  </div>
</template>

<script>
import NotFound from './NotFound.vue'

export default {
  components: {
    NotFound
  },
  data () {
    return {
      groupSlug: this.$route.params.slug
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.groupSlug = to.params.slug
    next()
  },
  computed: {
    services () {
      return this.$store.state.appServices.filter(
        (service) => service.group_id === this.currentGroup.id
      )
    },
    currentGroup () {
      return this.$store.state.serviceGroups.find(group => group.slug === this.groupSlug) || {}
    },
    isGroupSlugValid () {
      return !!Object.keys(this.currentGroup).length
    }
  },
  methods: {
    routeToHomepage () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.group-container {
  padding: 20px;
  .group-actions{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
    .icon-container{
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 1px solid @Black;
      margin-right: 10px;
    }
  }
  .services-container {
    overflow: auto;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 60px;
    height: auto;
    align-items: flex-start;
    padding: 1rem;
    flex-wrap: wrap;
    .service-item {
      .display-card();
    }
  }
  .group-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-services-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      font-size: 3rem;
      line-height: 3.125rem;
      letter-spacing: normal;
      font-weight: 400;
    }
    h6{
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: 0.0125em;
    }
  }
}
</style>
